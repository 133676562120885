






































































































































































































































import {
    computed,
    defineComponent,
    reactive,
    toRefs,
    watch,
} from "@vue/composition-api"
import UserChips from "@/components/UserChips.vue"
import TimeText from "@/components/TimeText.vue"
import KeywordInput from "@/components/KeywordInput.vue"
import DateTimeRangeInput from "@/components/DateTimeRangeInput.vue"
import Picture from "@/components/Picture.vue"
import {
    page,
    itemsPerPage,
    keyword,
    buildTimeRangeFilter,
    buildNumberFilter,
    buildBooleanFilter,
    buildNumberArrayFilter,
} from "@/services/QueryParmsService"
import { currentRoute } from "@/services/Router"
import {
    getMissions,
    MissionDTO,
    missionTypeFormatter,
    missionStateFormatter,
    deleteMission,
    deleteMissions,
    MissionViewModel,
    closeMission as callCloseMission,
    missionAddComputedInfo,
    missionTimeInfoFormatter,
    MissionViewModelWithComputedInfo,
    MissionState,
    MissionSortMethod,
    getMissionSignaturePicture,
} from "@/services/MissionsService"
import { timeFormatter } from "@/utilities/Formatter"
import {
    ButtonResult,
    DialogButtonType,
    DialogLevel,
    errorDialog,
    infoDialog,
    showDialog,
    successDialog,
} from "@/services/DialogService"
import { userCache } from "@/services/UsersClient"
import { SelectOption } from "@/plugins/vuetify"
import { hasPermission } from "@/services/AuthService"
import MakeMissionReportDialog from "./MakeMissionReportDialog.vue"

export default defineComponent({
    name: "Mission",
    components: {
        UserChips,
        TimeText,
        KeywordInput,
        DateTimeRangeInput,
        Picture,
        MakeMissionReportDialog,
    },
    setup() {
        const workTimeFilter = buildTimeRangeFilter("workTime")
        const startTimeFilter = buildTimeRangeFilter("startTime")
        const overdueTimeFilter = buildTimeRangeFilter("overdueTime")
        const stateFilter = buildNumberArrayFilter("state", [
            MissionState.Opened,
        ])
        const hasRoleOnly = buildBooleanFilter("hasRoleOnly", true)
        const sortMethod = buildNumberFilter(
            "s",
            MissionSortMethod.StartTimeDesc
        )
        const state = reactive({
            items: [] as MissionViewModel[],
            total: 0,
            loading: false,
            showMakeMissionDialog: false,
            makeMissionId: "",
        })

        const totalPages = computed(() =>
            Math.ceil(state.total / itemsPerPage.value)
        )

        const missionWithComputedInfo = computed(() =>
            state.items.map((item) => missionAddComputedInfo(item))
        )

        async function loadData() {
            state.loading = true
            const { total, items } = await getMissions({
                skip: itemsPerPage.value * (page.value - 1),
                take: itemsPerPage.value,
                withFields: true,
                withSignatureFields: true,
                keyword: keyword.value,
                workTimeStart: workTimeFilter.value.start,
                workTimeEnd: workTimeFilter.value.end,
                startTimeStart: startTimeFilter.value.start,
                startTimeEnd: startTimeFilter.value.end,
                overdueTimeStart: overdueTimeFilter.value.start,
                overdueTimeEnd: overdueTimeFilter.value.end,
                states: stateFilter.value,
                hasRoleOnly: hasRoleOnly.value,
                sort: sortMethod.value,
            })
            state.items = items.map((item) => {
                const result: Partial<MissionViewModel> = item
                result.managers = undefined
                result.assignees = undefined
                result.cooperators = undefined
                return result as MissionViewModel
            }) as MissionViewModel[]

            state.total = total
            await userCache.updateByIds(
                items.flatMap((item) =>
                    item.managerIds
                        .concat(item.assigneeIds)
                        .concat(item.cooperatorIds)
                )
            )
            state.items.forEach((item) => {
                item.managers = item.managerIds
                    .map((id) => userCache.get(id) ?? null)
                    .filter((user) => !!user)
                item.assignees = item.assigneeIds
                    .map((id) => userCache.get(id) ?? null)
                    .filter((user) => !!user)
                item.cooperators = item.cooperatorIds
                    .map((id) => userCache.get(id) ?? null)
                    .filter((user) => !!user)
            })
            state.loading = false
        }

        watch(
            () => currentRoute.value!.query,
            () => {
                loadData()
            }
        )

        loadData()

        async function removeMission(input: MissionDTO) {
            if (input.createdGroupId) {
                const { button } = await infoDialog("選擇刪除方法", undefined, [
                    "本任務",
                    "同時建立的任務",
                    "取消",
                ])
                if (button === ButtonResult.Cancel) return
                if (button === "同時建立的任務") {
                    const { button: confirmBatchDelete } = await errorDialog(
                        `確認要刪除同時建立的任務?`,
                        undefined,
                        DialogButtonType.ConfirmCancel
                    )
                    if (confirmBatchDelete === ButtonResult.Cancel) return
                    try {
                        await deleteMissions({
                            createdGroupId: input.createdGroupId,
                        })
                        successDialog("成功刪除")
                        loadData()
                    } catch (error) {
                        console.error(error)
                        errorDialog("刪除失敗，請聯絡系統維護人員")
                    }
                    return
                }
            }
            const { button: confirmDelete } = await errorDialog(
                `確認要刪除任務?`,
                undefined,
                DialogButtonType.ConfirmCancel
            )
            if (confirmDelete === ButtonResult.Cancel) return
            try {
                await deleteMission(input.id)
                successDialog("成功刪除")
                loadData()
            } catch (error) {
                console.error(error)
                errorDialog("刪除失敗，請聯絡系統維護人員")
            }
        }

        async function closeMission(mission: MissionViewModelWithComputedInfo) {
            const allFilled = mission.allFieldFilled
            const { button } = await showDialog(
                `確認關閉任務 ${mission.id} ?`,
                allFilled ? "" : "還有工項尚未填寫",
                allFilled ? DialogLevel.Info : DialogLevel.Warning,
                DialogButtonType.YesNo
            )
            if (button !== ButtonResult.Yes) return
            try {
                await callCloseMission(mission.id)
                successDialog("任務關閉成功")
                loadData()
            } catch (error) {
                console.error(error)
                errorDialog("任務關閉失敗，請聯絡系統維護人員")
            }
        }

        const hasAddMissionPermission = hasPermission("MISSION_ADD_MISSION")

        const stateOptions: SelectOption[] = [
            {
                text: "開放",
                value: MissionState.Opened,
            },
            {
                text: "關閉",
                value: MissionState.Closed,
            },
        ]

        const sortOptions = [
            {
                text: "ID",
                value: MissionSortMethod.Id,
            },
            {
                text: "主旨",
                value: MissionSortMethod.Subject,
            },
            {
                text: "開始時間早到晚",
                value: MissionSortMethod.StartTime,
            },
            {
                text: "開始時間晚到早",
                value: MissionSortMethod.StartTimeDesc,
            },
            {
                text: "截止時間早到晚",
                value: MissionSortMethod.OverdueTime,
            },
            {
                text: "截止時間晚到早",
                value: MissionSortMethod.OverdueTimeDesc,
            },
        ]

        async function downloadMissionReportWithDialog(id: string) {
            state.makeMissionId = id
            state.showMakeMissionDialog = true
        }

        return {
            ...toRefs(state),
            page,
            itemsPerPage,
            totalPages,
            loadData,
            missionTypeFormatter,
            missionStateFormatter,
            timeFormatter,
            removeMission,
            closeMission,
            missionWithComputedInfo,
            missionTimeInfoFormatter,
            workTimeFilter,
            startTimeFilter,
            overdueTimeFilter,
            stateFilter,
            stateOptions,
            hasRoleOnly,
            sortOptions,
            sortMethod,
            hasAddMissionPermission,
            downloadMissionReportWithDialog,
            getMissionSignaturePicture,
        }
    },
})
