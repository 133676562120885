

























































































import {
    computed,
    defineComponent,
    reactive,
    toRefs,
} from "@vue/composition-api"
import dayjs from "dayjs"

export default defineComponent({
    name: "DateTimeRangeInput",
    props: {
        label: { type: String, default: "" },
        errorMessages: [String, Array],
        value: {
            type: Object as () => {
                start: string | null
                end: string | null
            },
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        dense: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const state = reactive({
            menu: false,
        })

        const startTimeDayjs = computed(() =>
            !!props.value?.start ? dayjs(props.value?.start) : null
        )
        const endTimeDayjs = computed(() =>
            !!props.value?.end ? dayjs(props.value?.end) : null
        )

        const text = computed(() => {
            const startText = startTimeDayjs.value
                ? startTimeDayjs.value.format("YYYY-MM-DD HH:mm")
                : "--"
            const endText = endTimeDayjs.value
                ? endTimeDayjs.value.format("YYYY-MM-DD HH:mm")
                : "--"
            return `${startText} ~ ${endText}`
        })

        const normalizedStartDate = computed({
            get: () => {
                if (!startTimeDayjs.value) return ""
                return startTimeDayjs.value.format("YYYY-MM-DD")
            },
            set: (value) => {
                if (value === "") {
                    emit("input", {
                        start: null,
                        end: props.value?.end,
                    })
                    return
                }

                const newTime = dayjs(value)

                if (startTimeDayjs.value && endTimeDayjs.value) {
                    const diffInMinute = newTime.diff(
                        startTimeDayjs.value,
                        "minute"
                    )
                    endTimeDayjs.value.add(diffInMinute, "minutes")
                    emit("input", {
                        start: newTime.toISOString(),
                        end: endTimeDayjs.value
                            .add(diffInMinute, "minutes")
                            .toISOString(),
                    })
                } else if (
                    !startTimeDayjs.value &&
                    endTimeDayjs.value &&
                    newTime.isAfter(endTimeDayjs.value)
                ) {
                    emit("input", {
                        start: newTime.toISOString(),
                        end: newTime.toISOString(),
                    })
                } else {
                    emit("input", {
                        start: dayjs(value).toISOString(),
                        end: props.value?.end,
                    })
                }
            },
        })

        const normalizedEndDate = computed({
            get: () => {
                if (!endTimeDayjs.value) return ""
                return endTimeDayjs.value.format("YYYY-MM-DD")
            },
            set: (value) => {
                if (value === "")
                    emit("input", {
                        start: props.value?.start,
                        end: null,
                    })
                else
                    emit("input", {
                        start: props.value?.start,
                        end: dayjs(value).toISOString(),
                    })
            },
        })

        function blur() {
            emit("blur")
        }

        function clear() {
            emit("input", {
                start: null,
                end: null,
            })
        }

        function isEvent(date: string) {
            const dateDayjs = dayjs(date)
            if (startTimeDayjs.value && startTimeDayjs.value.isAfter(dateDayjs))
                return false
            if (endTimeDayjs.value && endTimeDayjs.value.isBefore(dateDayjs))
                return false
            return true
        }

        const presets = [
            {
                name: "今天",
                set: () => {
                    emit("input", {
                        start: dayjs().startOf("day").toISOString(),
                        end: dayjs().startOf("day").add(1, "day").toISOString(),
                    })
                },
            },
            {
                name: "這週",
                set: () => {
                    emit("input", {
                        start: dayjs().startOf("week").toISOString(),
                        end: dayjs()
                            .startOf("week")
                            .add(1, "week")
                            .toISOString(),
                    })
                },
            },
            {
                name: "下週",
                set: () => {
                    emit("input", {
                        start: dayjs()
                            .startOf("week")
                            .add(1, "week")
                            .toISOString(),
                        end: dayjs()
                            .startOf("week")
                            .add(2, "week")
                            .toISOString(),
                    })
                },
            },
            {
                name: "這月",
                set: () => {
                    emit("input", {
                        start: dayjs().startOf("month").toISOString(),
                        end: dayjs()
                            .startOf("month")
                            .add(1, "month")
                            .toISOString(),
                    })
                },
            },
            {
                name: "下月",
                set: () => {
                    emit("input", {
                        start: dayjs()
                            .startOf("month")
                            .add(1, "month")
                            .toISOString(),
                        end: dayjs()
                            .startOf("month")
                            .add(2, "month")
                            .toISOString(),
                    })
                },
            },
        ]

        return {
            ...toRefs(state),
            blur,
            clear,
            text,
            normalizedStartDate,
            normalizedEndDate,
            isEvent,
            presets,
        }
    },
})
