













































































import { defineComponent, reactive, toRefs } from "@vue/composition-api"
import {
    deleteMissionReportTemplate,
    downloadMissionReportTemplate,
    getMissionReportTemplates,
    makeMissionReport,
    postMissionReportTemplate,
} from "@/services/MissionsService"
import {
    ButtonResult,
    closeDialog,
    DialogButtonType,
    DialogLevel,
    errorDialog,
    showDialog,
    successDialog,
} from "@/services/DialogService"
import { hasPermission } from "@/services/AuthService"

export default defineComponent({
    name: "MakeMissionReportDialog",
    props: {
        showDialog: {
            type: Boolean,
            default: false,
        },
        id: {
            type: String,
            required: true,
        },
    },
    setup(props, { emit }) {
        const state = reactive({
            templates: [] as {
                id: string
                filename: string
            }[],
            selectedTemplateId: null as string | null,
            editing: false,
            file: null as File | null,
        })

        const hasModifyTemplatePermission = hasPermission("MISSION_MODIFY_TEMPLATE")

        function close() {
            emit("close")
        }

        async function loadTemplateNames() {
            state.templates = await getMissionReportTemplates()
        }

        loadTemplateNames()

        async function submit() {
            if (!state.selectedTemplateId) return

            showDialog(
                "任務報告產製中",
                "請稍後",
                DialogLevel.Info,
                DialogButtonType.None
            )
            await makeMissionReport(props.id, state.selectedTemplateId)
            closeDialog()
        }

        async function removeTemplate(id: string) {
            const { button: confirmDelete } = await errorDialog(
                `確認要刪除範本?`,
                undefined,
                DialogButtonType.ConfirmCancel
            )
            if (confirmDelete === ButtonResult.Cancel) return
            try {
                await deleteMissionReportTemplate(id)
                successDialog("成功刪除")
                loadTemplateNames()
            } catch (error) {
                console.error(error)
                errorDialog("刪除失敗，請聯絡系統維護人員")
            }
        }

        async function uploadTemplate() {
            if (!state.file) return
            await postMissionReportTemplate(state.file)
            loadTemplateNames()
            state.file = null
        }

        return {
            ...toRefs(state),
            close,
            submit,
            hasModifyTemplatePermission,
            removeTemplate,
            uploadTemplate,
            downloadMissionReportTemplate,
        }
    },
})
